<template>
  <div v-if="showTemplate">
    <div class="main">
      <div style="position: absolute">
        <img
          v-if="background != ''"
          class=""
          :src="link + background"
          alt=""
          style="height: 680px; width: 340px"
        />
        <img
          v-else
          class=""
          :src="background"
          alt=""
          style="height: 680px; width: 340px"
        />
      </div>
      <div style="position: relative">
        <div class="gold-lead">
          <TransitionGroup name="list" tag="ul">
            <div class="each" v-for="(val, index) in data[1]" :key="index">
              <div class="graph">
                <div style="display: block">
                  <p
                    :style="{
                      'font-family': fontName,
                      'font-size': sizeName + 'px',
                    }"
                    style="width: 95%; color: black"
                  >
                    {{ val.name }}
                  </p>
                  <p
                    class="percentage"
                    :style="{ '--graph': val.color }"
                    :class="{
                      ['percentage-' +
                      Math.ceil((val.gold * 100) / data[1][0].gold)]: true,
                    }"
                  ></p>
                  <div
                    class="text"
                    :style="{
                      color: goldColor,
                      'font-family': fontGold,
                      'font-size': sizeGold + 'px',
                    }"
                  >
                    {{ val.show }}
                  </div>
                </div>
              </div>
              <div class="profile">
                <img :src="link + val.hero + '?' + time" alt="" />
              </div>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </div>

    <div class="editor">
      <div style="display: flex; margin-bottom: 5px; margin-top: 5px">
        <div style="flex: 50%">
          <div v-for="(val, index) in data[1]" :key="index" style="margin: 5px">
            <div style="display: flex; height: 35px" v-if="index < 5">
              <label
                style="
                  flex: 10%;
                  margin-top: 10px;
                  margin-left: 10px;
                  color: white;
                "
                v-if="index < 5"
                >Left {{ index + 1 }}</label
              >
              <input type="text" class="input" v-model="val.name" />
              <Dropdown
                v-model="val.hero"
                :options="heroList"
                optionLabel="title"
                optionValue="file_name"
                :placeholder="'Select Image ' + (index + 1)"
                :filter="true"
                style="width: 50%"
                :autoFilterFocus="true"
                :resetFilterOnHide="true"
                @change="ChangeImage(val.id, $event)"
                ref="dropdown"
              >
              </Dropdown>
            </div>
          </div>
        </div>
        <div style="flex: 50%">
          <div v-for="(val, index) in data[1]" :key="index" style="margin: 5px">
            <div style="display: flex; height: 35px" v-if="index > 4">
              <label
                style="
                  flex: 10%;
                  margin-top: 10px;
                  margin-left: 10px;
                  color: white;
                "
                v-if="index > 4"
                >Right {{ index + 1 - 5 }}</label
              >
              <input type="text" class="input" v-model="val.name" />
              <Dropdown
                v-model="val.hero"
                :options="heroList"
                optionLabel="title"
                optionValue="file_name"
                :placeholder="'Select Image ' + (index + 1 - 5)"
                :filter="true"
                style="width: 50%"
                :autoFilterFocus="true"
                :resetFilterOnHide="true"
                @change="ChangeImage(val.id, $event)"
                ref="dropdown"
              >
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; margin-left: 7px">
        <div style="flex: 25%">
          <label style="color: white">Font Name</label>
          <div
            style="
              margin: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="fontName"
              @change="ChangeFont('name')"
            >
              <option
                v-for="font in fontList"
                :key="font"
                :style="{ 'font-family': font }"
              >
                {{ font }}
              </option>
            </select>
          </div>
        </div>
        <div style="flex: 25%">
          <label style="color: white">Name Font Size</label>
          <div
            style="
              margin: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <input
              type="number"
              v-model="sizeName"
              @change="ChangeSize('name')"
              style="width: 100%; border-radius: 7px; height: 100%"
            />
          </div>
        </div>
        <div style="flex: 25%">
          <label style="color: white">Font Number</label>

          <div
            style="
              margin: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="fontGold"
              @change="ChangeFont('gold')"
            >
              <option
                v-for="font in fontList"
                :key="font"
                :style="{ 'font-family': font }"
              >
                {{ font }}
              </option>
            </select>
          </div>
        </div>
        <div style="flex: 25%">
          <label style="color: white">Font Size</label>
          <div
            style="
              margin: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <input
              type="number"
              v-model="sizeGold"
              @change="ChangeSize('gold')"
              style="width: 100%; border-radius: 7px; height: 100%"
            />
          </div>
        </div>
        <div style="flex: 25%">
          <label style="margin-left: 10px; color: white">Left Color</label>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div>
              <input
                type="color"
                v-model="graphLeft"
                @change="ChangeColor('left')"
                id="color1"
              />
            </div>
            <div style="flex: 95%">
              <label for="" style="color: aliceblue">{{ graphLeft }}</label>
            </div>
          </div>
        </div>
        <div style="flex: 25%">
          <label style="margin-left: 10px; color: white">Right Color</label>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div>
              <input
                type="color"
                v-model="graphRight"
                @change="ChangeColor('right')"
                id="color1"
              />
            </div>
            <div style="flex: 50%">
              <label for="" style="color: aliceblue">{{ graphRight }}</label>
            </div>
          </div>
        </div>
        <div style="flex: 25%">
          <label style="margin-left: 10px; color: white">Gold Color</label>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div>
              <input
                type="color"
                v-model="goldColor"
                @change="ChangeColor('gold')"
                id="color1"
              />
            </div>
            <div style="flex: 50%">
              <label for="" style="color: aliceblue">{{ graphRight }}</label>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div style="flex: 50%; margin: 5px">
          <button
            class="btn btn-secondary"
            style="
              width: 14%;
              margin-left: 5px;
              pointer-events: none;
              background-color: white;
            "
          >
            {{ title }}
          </button>
          <button
            class="btn btn-warning"
            @click="ShowDialog('image')"
            style="width: 28%; margin-left: 5px"
          >
            Choose Background
          </button>
          <button
            style="width: auto"
            class="btn btn-warning"
            @click="ClearNumber"
          >
            Clear
          </button>
          <button style="width: 10%" class="btn btn-warning" @click="CopyLink">
            Copy Link
          </button>
          <button
            style="width: 38%"
            class="btn btn-warning"
            @click="Save"
            :disabled="isSave"
          >
            Save
          </button>
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="fileDialog"
      :style="{ width: '1700px' }"
      :modal="true"
      :draggable="false"
      class="p-fluid"
      :closeOnEscape="false"
    >
      <template #header>
        <div>
          <ul class="nav nav-tabs" role="tablist">
            <li
              id="images"
              class="nav-item flex-2"
              :class="{ active: tabActive == 'image' }"
              role="presentation"
            >
              <button
                class="nav-link w-full"
                :class="{ active: tabActive == 'image' }"
                data-tw-toggle="pill"
                data-tw-target="#images"
                type="button"
                role="tab"
                aria-controls="images"
                aria-selected="false"
                @click="GetFile('image')"
              >
                <i class="bi bi-card-image" style="margin-right: 10px"></i>
                Images
              </button>
            </li>
            <li
              id="Upload"
              class="nav-item flex-2"
              style="margin-top: 1px"
              role="presentation"
              :class="{ active: tabActive == 'upload' }"
            >
              <FileUpload
                id="file_name"
                :customUpload="true"
                :multiple="true"
                :showCancelButton="false"
                :showUploadButton="false"
                chooseLabel="Upload"
                @uploader="UploadImage"
                :auto="true"
                mode="basic"
                accept="image/*,.mp4,.mov"
              >
              </FileUpload>
            </li>
          </ul>
        </div>
      </template>
      <div
        class="intro-y grid grid-cols-12 gap-3 sm:gap-6 p-5"
        v-if="tabActive != 'upload'"
      >
        <div
          class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2"
          v-for="file in files"
          :key="file.id"
        >
          <div
            class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in image"
          >
            <a
              href="javascript:;"
              class="w-3/5 file__icon file__icon--image mx-auto"
              @click="SetFile(file, $event)"
            >
              <div class="file__icon--image__preview image-fit">
                <img
                  v-if="file.type == 'image'"
                  alt="Midone - HTML Admin Template"
                  :src="link + file.file_name"
                />
                <video
                  autoplay="false"
                  muted="true"
                  loop="false"
                  v-else-if="file.type == 'video'"
                >
                  <source :src="link + file.file_name" />
                </video>
              </div>
            </a>
            <a
              href="javascript:;"
              class="block font-medium mt-4 text-center truncate name"
              >{{ file.title }}</a
            >
            <div class="text-slate-500 text-xs text-center mt-0.5"></div>
            <div class="absolute top-0 right-0 mr-2 mt-3 ml-auto">
              <a
                href="javascript:;"
                @click="DeleteFile(file.id)"
                v-if="file.state == null"
              >
                <i class="w-4 h-4 bi bi-trash3-fill"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <Toast position="top-right" group="tr" />
  </div>
</template>

<script>
import axios from 'axios';
import db from '../firebase';
import { ref, onValue, get, child } from 'firebase/database';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import VueCryptojs from 'vue-cryptojs';

export default {
  name: 'GraphView',
  components: {
    Dropdown,
    InputText,
    Dialog,
    FileUpload,
    Toast,
    VueCryptojs,
  },

  data() {
    return {
      showTemplate: false,
      link: process.env.VUE_APP_Link,
      data: [],
      id: this.$route.query.data,
      background: '',
      heroList: [],
      fileDialog: false,
      file: [],
      files: [],
      graphLeft: '',
      graphRight: '',
      goldColor: '',
      nameColor: '',
      fontName: '',
      fontGold: '',
      sizeGold: '',
      sizeName: '',
      isSave: false,
      title: '',
      time: '',
      fontList: [
        'Abril Fatface',
        'AKbalthom Kbach',
        'Amatic SC',
        'Architects Daughter',
        'Bangers',
        'BricolageGrotesque-SemiBold',
        'Chewy',
        'Comfortaa',
        'Covered By Your Grace',
        'Courgette',
        'Cinzel',
        'Dancing Script',
        'Gloria Hallelujah',
        'Josefin Sans',
        'Kaushan Script',
        'Khmer Pen Eng',
        'Lobster',
        'Lobster Two',
        'Montez',
        'Orbitron',
        'Pacifico',
        'PLAYBILL',
        'Rokkitt',
        'Righteous',
        'Russo_One',
        'SansSerifBldFLF',
        'SansSerifBldFLFCond',
        'SansSerifBldFLFCond-Italic',
        'SansSerifBldFLF-Italic',
        'Satisfy',
        'Shadows Into Light',
        'Sigmar One',
        'SpaceGrotesk-Regular',
        'SpaceGrotesk-Bold',
        'SpaceGrotesk-Light',
        'SpaceGrotesk-Medium',
        'SpaceGrotesk-SemiBold',
        'SpaceGrotesk-VariableFont_wght',
        'Tungsten-Semibold',
        'quickdude',
        'GT Walsheim Pro Bold',
        'GT Walsheim Pro Regular',
        'CHAKRAPETCH-BOLD',
        'CHAKRAPETCH-SEMIBOLD',
        'TUNGSTEN-BOLD',
        'TUNGSTENCOND-BLACK',
        'TUNGSTENCOND-BOLD',
        'TUNGSTENCOND-SEMIBOLD',
        'TUNGSTEN-SEMIBOLD',
      ],
    };
  },

  created() {
    let self = this;
    window.addEventListener('keydown', function (event) {
      if (event.ctrlKey && event.altKey && event.key == 's') {
        event.preventDefault();
        if (!self.isSave) {
          self.Save('save');
        }
      }
    });
    var result = this.id.includes('mmm'),
      element_id = '';
    if (result) {
      var split = this.id.split('mmm');
      element_id = split[0];

      onValue(ref(db, element_id), (snapshot) => {
        const data = snapshot.val();
        if (data.overlays != '') {
          this.data = data.overlays;
          const sortedArr = this.data[1].sort((a, b) => {
            return b.gold - a.gold;
            // if (a.gold > b.gold) {
            //   return -1;
            // }
            // if (a.gold < b.gold) {
            //   return 1;
            // }
            // return 0;
          });
          this.data[1] = sortedArr;
        } else {
          this.inputs = '';
          this.showElement = false;
        }
      });
    } else {
      onValue(ref(db, 'overlay/'), (snapshot) => {
        const data = snapshot.val();
        if (data.overlays != '') {
          this.data = data.overlays;
        } else {
          this.inputs = '';
          this.showElement = false;
        }
      });
    }
  },

  mounted() {
    setInterval(this.GetNow, 60000);
    this.showTemplate = true;
    this.GetImage();
    this.getOverlay();
  },

  methods: {
    GetNow() {
      var date = new Date();
      this.time = date.getTime();
    },
    ChangeSize(val) {
      if (val == 'name') {
        this.data[0][0].sizeName = this.sizeName;
      } else {
        this.data[0][0].sizeGold = this.sizeGold;
      }
    },
    ChangeFont(val) {
      if (val == 'name') {
        this.data[0][0].fontName = this.fontName;
      } else {
        this.data[0][0].fontGold = this.fontGold;
      }
    },

    ChangeColor(val) {
      if (val == 'left') {
        for (var i = 0; i < this.data[1].length; i++) {
          if (this.data[1][i].id < 5) {
            this.data[1][i].color = this.graphLeft;
            // this.data[1][i].color1 = this.graphRight;
          }
        }
      } else if (val == 'right') {
        for (var i = 0; i < this.data[1].length; i++) {
          if (this.data[1][i].id > 4) {
            this.data[1][i].color = this.graphRight;
            // this.data[1][i].color1 = this.graphLeft;
          }
        }
      } else if (val == 'gold') {
        this.data[0][0].gold = this.goldColor;
        for (var i = 0; i < this.data[1].length; i++) {
          if (this.data[1][i].id < 10) {
            this.data[1][i].color1 = this.goldColor;
          }
        }
      } else if (val == 'name') {
        this.data[0][0].name = this.nameColor;
      }
    },

    CopyLink() {
      var url = window.location.href;
      try {
        navigator.clipboard.writeText(url);
        this.Toast('success', 'Copied', '');
      } catch ($e) {
        this.Toast('fail', 'Cannot copy', '');
      }
    },

    ClearNumber() {
      for (var i in this.data[1]) {
        this.data[1][i].gold = 0;
        this.data[1][i].show = 0;
      }
      const sortedArr = this.data[1].sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
      this.data[1] = sortedArr;
    },

    async Save() {
      this.isSave = true;
      var split = this.id.split('mmm'),
        token = split[1],
        element_id = split[0];
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      var data = {
        id: element_id,
        component: JSON.stringify(this.data),
        live: 1,
      };
      await axios
        .post('update_overlay_auto', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            this.getOverlay();
            this.Toast('success', 'Saved', '');
            this.isSave = false;
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },

    async UploadImage(event) {
      this.file = event.files;
      var split = this.id.split('mmm'),
        token = split[1],
        element_id = split[0];
      var form = new FormData();
      this.file.forEach((element) => {
        form.append('file[]', element);
        form.append('idx', element_id);
      });
      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'ultipart/form-data',
        },
      };
      await axios
        .post('store_file', form, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            this.tabActive = resp.data.data;
            this.GetFile(resp.data.data);
            this.Toast('success', 'Saved', '');
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },

    DeleteFile(id) {
      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        id: id,
      };
      axios
        .post('delete_file', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            this.GetFile(this.tabActive);
            this.Toast('success', 'Delete Success', '');
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },

    async GetFile(val) {
      if (val == 'allfile') {
        this.tabActive = '';
        var type = '';
      } else if (val == 'image') {
        this.tabActive = 'image';
        var type = 'image';
      } else if (val == 'video') {
        this.tabActive = 'video';
        var type = 'video';
      } else if (val == 'item') {
        this.tabActive = 'item';
        var type = 'item';
      }

      var split = this.id.split('mmm'),
        token = split[1],
        element_id = split[0];
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: type,
        id: element_id,
      };
      await axios
        .post('get_files', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            if (val == 'allfile') {
              this.files = resp.data.data;
            } else if (val == 'image') {
              this.files = resp.data.data;
            } else if (val == 'video') {
              this.files = resp.data.data;
            } else if (val == 'item') {
              this.files = resp.data.data;
            }
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },

    SetFile(val) {
      this.data[0][0].background = val.file_name;
      this.background = val.file_name;
      this.fileDialog = false;
    },

    ShowDialog(val) {
      this.btnClick = val;
      this.tabActive = val;
      this.fileDialog = true;
      this.GetFile(val);
    },

    ChangeImage(id, evt) {
      var overlay = this.data[1].find((x) => x.id === id);
      if (overlay) {
        overlay.hero = evt.value;
      }
    },

    async GetImage() {
      var split = this.id.split('mmm'),
        token = split[1],
        element_id = split[0];
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: 'image',
        id: element_id,
      };
      await axios
        .post('get_all_images', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            this.heroList = resp.data.data.threeByTwo;
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },

    decrypt(src) {
      if (src != null) {
        const passphrase = 'Overlay';
        const bytes = this.$CryptoJS.AES.decrypt(src, passphrase);
        const originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
        return originalText;
      } else {
        return src;
      }
    },

    getOverlay() {
      var result = this.id.includes('mmm');
      let token = '';
      let element_id = '';
      if (result) {
        var split = this.id.split('mmm');
        token = split[1];
        element_id = split[0];

        var data = {
          id: element_id,
        };
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        axios
          .post('get_overlay_by_id', data, config)
          .then((resp) => {
            if (resp.data.data != null) {
              if (resp.data.data.status == 1) {
                this.title = resp.data.data.title;
                this.data = JSON.parse(resp.data.data.component);
                this.background = this.data[0][0].background;
                this.goldColor = this.data[0][0].gold;
                this.nameColor = this.data[0][0].name;
                this.fontGold = this.data[0][0].fontGold;
                this.fontName = this.data[0][0].fontName;
                this.sizeGold = this.data[0][0].sizeGold;
                this.sizeName = this.data[0][0].sizeName;
                for (var i = 0; i < this.data[1].length; i++) {
                  if (this.data[1][i].id == 0) {
                    this.graphLeft = this.data[1][i].color;
                  }
                  if (this.data[1][i].id == 5) {
                    this.graphRight = this.data[1][i].color;
                  }
                }
              }
            }
          })
          .catch((err) => {
            if (err.response.statusText == 'Unauthorized') {
              localStorage.removeItem('token');
              localStorage.removeItem('username');
              localStorage.removeItem('id');
              this.$router.push('login');
            } else {
              console.log(err.response.statusText);
            }
          });
      }
    },

    Toast(state, sum, det) {
      this.$toast.add({
        severity: state,
        summary: sum,
        detail: det,
        group: 'tr',
        life: 800,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  position: fixed;
  top: 10px;
  left: 10%;
  width: 340px;
}

.editor {
  overflow: auto;
  position: fixed;
  background-color: #332601;
  width: 1000px;
  height: 350px;
  bottom: 10px;
  left: 30%;
  border-radius: 10px;
}

.current-hp {
  margin: 81px 0px 0px 3px;
}

.gold-lead {
  margin: 80px 0px 0px 3px;
}

.current-hp .each {
  display: flex;
  position: relative;
  height: 57px;
}

.gold-lead .each {
  display: flex;
  position: relative;
  height: 60px;
}

.current-hp .profile {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gold-lead .profile {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
}

.profile img {
  height: 55px;
  width: 120px;
}

.current-hp .graph {
  flex: 2 0 auto;
  padding-right: 10px;
  margin-left: 120px;
  margin-top: 15px;
}

.gold-lead .graph {
  width: 66%;
  margin-top: 10px;
}

.current-hp .graph p:first-child {
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.gold-lead .graph p:first-child {
  color: rgb(0, 0, 0);
  font-weight: 500;
  text-align: right;
  // right: 00px;
  // float: right;
}

.percentage {
  width: 95%;
  height: 20px;
  transform: rotate(180deg);
}

.percentage:after {
  content: '';
  display: block;
  background-color: var(--graph) !important;
  height: 100%;
  position: relative;
  transform: translateY(-10%);
  transition: background-color 0.3s ease;
}

@for $i from 0 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 1%);
      width: $value;
    }
  }
}

.current-hp .text {
  //font-size: 1.5em;
  font-weight: 900;
  position: relative;
  left: 5px;
  z-index: 1;
}

.gold-lead .text {
  // font-size: 1em;
  // font-weight: 800;
  left: 100px;
  z-index: 1;
  position: relative;
  text-align: right;
  width: 105px;
  transform: translateY(-85%);
}

.input {
  width: 100px;
  border-radius: 5px;
  flex: 30%;
  margin-right: 5px;
}

.btn {
  margin-right: 5px;
}

#color1 {
  border: none;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin: 10px;
}

#color1::-webkit-color-swatch {
  border: none;
  border-radius: 15px;
  padding: 0;
}

#color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 15px;
  padding: 0;
}

label {
  margin-left: 5px;
}
</style>
