<template>
  <!-- name="fade"
  :duration="{ enter: 1000, leave: 1000 }" -->
  <Transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="transform -translate-x-full -translate-y-full opacity-0"
    enter-to-class="transform translate-x-0 translate-y-0 opacity-100"
    leave-active-class="transition ease-in duration-500 delay-200"
    leave-from-class="transform translate-x-0 translate-y-0 opacity-100"
    leave-to-class="transform -translate-x-full -translate-y-full opacity-0"
  >
    <div
      style="font-family: quickdude"
      :style="{ width: state.w + 'px', height: state.h + 'px' }"
      v-show="showState"
      class="absolute inset-0 backdrop-blur-sm mask-diagonal-red flex items-center justify-center backState"
    ></div>
  </Transition>

  <Transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="transform -translate-x-full -translate-y-full opacity-0"
    enter-to-class="transform translate-x-0 translate-y-0 opacity-100"
    leave-active-class="transition ease-in duration-500 delay-200"
    leave-from-class="transform translate-x-0 translate-y-0 opacity-100"
    leave-to-class="transform -translate-x-full -translate-y-full opacity-0"
  >
    <div
      class="absolute inset-0 backdrop-blur-sm mask-diagonal-red flex items-center justify-center backState"
      style="font-family: quickdude"
      :style="{ width: state.w + 'px', height: state.h + 'px' }"
      v-if="showState"
    ></div>
  </Transition>

  <!-- Down Drop -->
  <Transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="transform translate-x-full translate-y-full opacity-0"
    enter-to-class="transform translate-x-0 translate-y-0 opacity-100"
    leave-active-class="transition ease-in duration-500 delay-200"
    leave-from-class="transform translate-x-0 translate-y-0 opacity-100"
    leave-to-class="transform translate-x-full translate-y-full opacity-0"
  >
    <div
      class="absolute inset-0 backdrop-blur-sm mask-diagonal-blue flex items-center justify-center backState"
      style="font-family: quickdude"
      :style="{ width: state.w + 'px', height: state.h + 'px' }"
      v-if="showState"
    ></div>
  </Transition>

  <Transition
    enter-active-class="transition ease-out duration-300 delay-500"
    enter-from-class="transform scale-110 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition ease-out duration-300"
    leave-from-class="transform scale-110 opacity-100"
    leave-to-class="transform scale-100 opacity-0"
  >
    <div
      v-if="showState"
      class="absolute inset-0"
      style="font-family: quickdude"
    >
      <div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            text-align: center;
            margin-block: 15px;
          "
        >
          <div class="" style="padding-top: 25px">
            <p style="color: black; font-size: 1.5cqi; font-family: quickdude">
              {{ name }}
            </p>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            text-align: center;
            margin-block: 20px;
          "
        >
          <div>
            <p
              style="
                color: black;
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 1.3rem;
              "
            >
              PICK
            </p>
            <p style="color: blue; font-weight: bold; font-size: 2.22rem">
              {{ pick }}
            </p>
          </div>
          <div>
            <p
              style="
                color: black;
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 1.3rem;
              "
            >
              BAN
            </p>
            <p style="color: red; font-weight: bold; font-size: 2.22rem">
              {{ ban }}
            </p>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 20px;
          "
        >
          <div>
            <p
              style="
                color: black;
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 1.3rem;
              "
            >
              WIN RATE
            </p>
            <p style="color: #9600ff; font-weight: bold; font-size: 2rem">
              {{ winRate }}
            </p>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            text-align: center;
            margin-block: 10px;
          "
        >
          <div>
            <h1 style="color: #f2c701; font-weight: bold; margin-block: 10px">
              RECENT K/D/A
            </h1>
            <p style="color: white; font-weight: bold; font-size: 1.5rem">
              {{ `${kill}/${death}/${assist}` }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  name: 'BackStateCom',
  props: {
    state: Object,
    source: String,
  },
  data() {
    return {
      pick: this.state.heroState.pickCount || 0,
      ban: this.state.heroState.banCount || 0,
      winRate: this.state.heroState.winRate || 0,
      kill: this.state.heroState.kill || 0,
      death: this.state.heroState.death || 0,
      assist: this.state.heroState.assist || 0,
      name: this.state.heroState.name || '',
      showTemplate: true,
      show: false,
      link: process.env.VUE_APP_Link,
      showState: false,
    };
  },

  mounted() {
    this.show = true;
    // this.showState = true;
    var self = this;
    setTimeout(() => {
      self.showState = true;
    }, 2500);
    setTimeout(() => {
      self.showState = false;
    }, 5500);
  },

  watch: {
    text: {
      deep: true,
      immediate: true,
      handler() {},
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active {
  animation: slide-down 1s;
  z-index: 9999;
  // opacity: 1;
}

.fade-leave-active {
  animation: slide-up 1s;
  // opacity: 0;
}

.fade-enter-from {
  transform: translateY(-300px);
  // opacity: 1;
}

.fade-leave-to {
  transform: translateY(0);
  display: none;
  opacity: 0;
}

@keyframes slide-down {
  0% {
    transform: translateY(-300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    // opacity: 1;
  }

  100% {
    transform: translateY(-300px);
    // opacity: 0;
  }
}

.backState {
  background-image: url('/public/back_state_bg.png');
  background-position: center;
  background-repeat: none;
  // background-color: rgba(0, 0, 0, .8);
  position: absolute;
  top: 0px;
  font-size: 15px;
}

.mask-diagonal-red {
  -webkit-mask-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 50%
  );
  mask-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 50%
  );
}

.mask-diagonal-blue {
  -webkit-mask-image: linear-gradient(
    to top left,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 50%
  );
  mask-image: linear-gradient(
    to top left,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 50%
  );
}
</style>
