<template>
  <div v-if="showTemplate">
    <audio src="" id="heroAudio" style="display: none"></audio>
    <div class="fluidMedia" :style="{ 'background-color': color }">
      <VueDragResize
        v-for="input in inputsAll"
        :key="input.style.time"
        :isActive="input.active"
        :x="input.x"
        :y="input.y"
        :w="input.w"
        :h="input.h"
        :for="input.id"
        :id="input.id"
        v-show="input.hide == false && inputs != ''"
        :parentLimitation="true"
        :isDraggable="false"
        :isResizable="false"
        :class="{
          ['rotate' + input.style.rotate]: true,
          blinking: input.style.blink,
        }"
        style="overflow: hidden; user-select: none"
        :style="{ opacity: input.style.opacity + '%', 'z-index': input.z }"
      >
        <video-com
          v-if="input.type === 'video' && showElement"
          :state="input"
          :source="link + input.source + '?' + input.style.time"
        ></video-com>
        <image-com
          v-if="input.type === 'image' && showElement"
          class="logo overrides"
          :source="link + input.source + '?' + input.style.time"
          :class="{ grayClass: input.style.gray }"
          :state="input"
        ></image-com>
        <pre
          v-if="input.type === 'text' && showElement"
          :style="{
            color: input.style.color,
            'font-family': input.style.font,
            'font-size': input.style.size + 'px',
            'text-align': input.style.align,
            'line-height': input.h + 'px',
          }"
          >{{ input.source }}
      </pre
        >
        <count-down
          :key="input.style.time"
          v-if="input.type === 'counter' && showElement && input.clock == false"
          :second="input.totalSeconds"
          :text="input.message"
          :state="input"
        ></count-down>
        <Clock
          :key="input.style.time"
          v-if="input.type === 'counter' && showElement && input.clock == true"
          :text="input.message"
          :second="input.totalSeconds"
          :state="input"
          :btn="input.op"
        ></Clock>
        <Graph
          :key="input.style.time"
          v-if="input.type === 'graph' && showElement"
          :state="input"
        >
        </Graph>
      </VueDragResize>
    </div>

    <div
      class="w-[100vw] flex items-center justify-center"
      style="position: absolute; bottom: 8px"
      v-if="newBanPick && inputs != ''"
    >
      <div class="w-[704px] mr-20" style="margin-right: 190px">
        <div class="mb-8 bg-black shadow-lg w-full h-[250px] relative">
          <div
            :style="{
              width: pickL1 ? 100 + '%' : '20' + '%',
              'z-index': pickL1 ? 99 : 0,
              left: 0,
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockL1"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <div v-if="imgSrc[0].state.source != ''">
              <div v-if="!imgSrc[0].state.source.includes('webm')">
                <transition
                  v-if="pickL1"
                  name="fade"
                  :duration="{ enter: 3000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[0].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[0].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[0].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[0].source"
                  style="object-position: 100% 20%"
                />
              </div>

              <div v-else>
                <transition
                  v-if="pickL1"
                  name="fade"
                  :duration="{ enter: 3000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[0].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[0].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay
                      muted
                      loop
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[0].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[0].time"
                  style="object-position: 100% 20%"
                  autoplay
                  muted
                  loop
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[0].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[0].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <!-- style="object-position: 100% 10%;"  -->
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #0046fb; color: white"
            >
              {{
                imgSrc[0].name ? imgSrc[0].name.replace(/\..+$/, '') : 'Hero 1'
              }}
            </div>
            <back-state-com
              :state="imgSrc[0].state"
              :source="imgSrc[0].source"
              :key="imgSrc[0].time"
              v-if="
                imgSrc[0].state.style.state &&
                imgSrc[0].state.style.swapState == false &&
                pickL1 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickL2 ? 50 + '%' : '20' + '%',
              'z-index': pickL2 ? 99 : 0,
              left: pickL2 ? '0' : '20' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockL2"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[1].state.source.includes('webm')" class="relative w-full object-cover h-[250px]"
              :src="link + imgSrc[1].source" style="object-position: 100% 5%;"> -->
            <div v-if="imgSrc[1].state.source != ''">
              <div v-if="!imgSrc[1].state.source.includes('webm')">
                <transition
                  v-if="pickL2"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[1].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[1].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[1].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[1].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickL2"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[1].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[1].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[1].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[1].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[1].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[1].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #0046fb; color: white"
            >
              {{
                imgSrc[1].name ? imgSrc[1].name.replace(/\..+$/, '') : 'Hero 2'
              }}
            </div>
            <back-state-com
              :state="imgSrc[1].state"
              :source="imgSrc[1].source"
              :key="imgSrc[1].time"
              v-if="
                imgSrc[1].state.style.state &&
                imgSrc[1].state.style.swapState == false &&
                pickL2 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickL2 ? 50 + '%' : '20' + '%',
              'z-index': pickL2 ? 99 : 0,
              left: pickL2 ? '50' + '%' : '40' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockL3"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[2].state.source.includes('webm')" class="relative w-full object-cover h-[250px]"
              :src="link + imgSrc[2].source" style="object-position: 100% 5%;"> -->
            <div v-if="imgSrc[2].state.source != ''">
              <div v-if="!imgSrc[2].state.source.includes('webm')">
                <transition
                  v-if="pickL2"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[2].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[2].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[2].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[2].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickL2"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[2].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[2].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[2].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[2].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[2].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[2].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #0046fb; color: white"
            >
              {{
                imgSrc[2].name ? imgSrc[2].name.replace(/\..+$/, '') : 'Hero 3'
              }}
            </div>
            <back-state-com
              :state="imgSrc[2].state"
              :source="imgSrc[2].source"
              :key="imgSrc[2].time"
              v-if="
                imgSrc[2].state.style.state &&
                imgSrc[2].state.style.swapState == false &&
                pickL2 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickL3 ? 50 + '%' : '20' + '%',
              'z-index': pickL3 ? 99 : 0,
              left: pickL3 ? '0' : '60' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockL4"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[3].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[3].source"> -->
            <div v-if="imgSrc[3].state.source != ''">
              <div v-if="!imgSrc[3].state.source.includes('webm')">
                <transition
                  v-if="pickL3"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[3].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[3].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[3].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[3].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickL3"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[3].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[3].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[3].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[3].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[3].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[3].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #0046fb; color: white"
            >
              {{
                imgSrc[3].name ? imgSrc[3].name.replace(/\..+$/, '') : 'Hero 4'
              }}
            </div>
            <back-state-com
              :state="imgSrc[3].state"
              :source="imgSrc[3].source"
              :key="imgSrc[3].time"
              v-if="
                imgSrc[3].state.style.state &&
                imgSrc[3].state.style.swapState == false &&
                pickL3 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickL3 ? 50 + '%' : '20' + '%',
              'z-index': pickL3 ? 99 : 0,
              left: pickL3 ? '50' + '%' : '80' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockL5"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[4].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[4].source"> -->
            <div v-if="imgSrc[4].state.source != ''">
              <div v-if="!imgSrc[4].state.source.includes('webm')">
                <transition
                  v-if="pickL3"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[4].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[4].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[4].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[4].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickL3"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[4].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[4].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[4].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[4].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[4].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[4].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #0046fb; color: white"
            >
              {{
                imgSrc[4].name ? imgSrc[4].name.replace(/\..+$/, '') : 'Hero 5'
              }}
            </div>
            <back-state-com
              :state="imgSrc[4].state"
              :source="imgSrc[4].source"
              :key="imgSrc[4].time"
              v-if="
                imgSrc[4].state.style.state &&
                imgSrc[4].state.style.swapState == false &&
                pickL3 == false
              "
            />
          </div>
        </div>
      </div>

      <div class="w-[704px] ml-20" style="margin-left: 190px">
        <div class="mb-8 bg-black shadow-lg w-full h-[250px] relative">
          <div
            :style="{
              width: pickR4 ? 100 + '%' : '20' + '%',
              'z-index': pickR4 ? 99 : 0,
              left: 0,
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockR1"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[5].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[5].source"> -->
            <div v-if="imgSrc[5].state.source != ''">
              <div v-if="!imgSrc[5].state.source.includes('webm')">
                <transition
                  v-if="pickR4"
                  name="fade"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[5].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[5].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[5].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[5].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickR4"
                  name="fade"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[5].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[5].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[5].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[5].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[5].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[5].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #f90033; color: white"
            >
              {{
                imgSrc[5].name ? imgSrc[5].name.replace(/\..+$/, '') : 'Hero 1'
              }}
            </div>
            <back-state-com
              :state="imgSrc[5].state"
              :source="imgSrc[5].source"
              :key="imgSrc[5].time"
              v-if="
                imgSrc[5].state.style.state &&
                imgSrc[5].state.style.swapState == false &&
                pickR4 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickR3 ? 100 + '%' : '20' + '%',
              'z-index': pickR3 ? 99 : 0,
              left: pickR3 ? '0' : '20' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockR2"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[6].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[6].source"> -->
            <div v-if="imgSrc[6].state.source != ''">
              <div v-if="!imgSrc[6].state.source.includes('webm')">
                <transition
                  v-if="pickR3"
                  name="fade"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[6].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[6].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[6].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[6].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickR3"
                  name="fade"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[6].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[6].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[6].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[6].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[6].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[6].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #f90033; color: white"
            >
              {{
                imgSrc[6].name ? imgSrc[6].name.replace(/\..+$/, '') : 'Hero 2'
              }}
            </div>
            <back-state-com
              :state="imgSrc[6].state"
              :source="imgSrc[6].source"
              :key="imgSrc[6].time"
              v-if="
                imgSrc[6].state.style.state &&
                imgSrc[6].state.style.swapState == false &&
                pickR3 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickR2 ? 100 + '%' : '20' + '%',
              'z-index': pickR2 ? 99 : 0,
              left: pickR2 ? '0' + '%' : '40' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockR3"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[7].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[7].source"> -->
            <div v-if="imgSrc[7].state.source != ''">
              <div v-if="!imgSrc[7].state.source.includes('webm')">
                <transition
                  v-if="pickR2"
                  name="fade"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[7].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[7].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[7].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[7].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickR2"
                  name="fade"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[7].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[7].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[7].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[7].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[7].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[7].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #f90033; color: white"
            >
              {{
                imgSrc[7].name ? imgSrc[7].name.replace(/\..+$/, '') : 'Hero 3'
              }}
            </div>
            <back-state-com
              :state="imgSrc[7].state"
              :source="imgSrc[7].source"
              :key="imgSrc[7].time"
              v-if="
                imgSrc[7].state.style.state &&
                imgSrc[7].state.style.swapState == false &&
                pickR2 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickR1 ? 50 + '%' : '20' + '%',
              'z-index': pickR1 ? 99 : 0,
              left: pickR1 ? '0' : '60' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockR4"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[8].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[8].source"> -->
            <div v-if="imgSrc[8].state.source != ''">
              <div v-if="!imgSrc[8].state.source.includes('webm')">
                <transition
                  v-if="pickR1"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[8].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[8].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[8].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[8].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickR1"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[8].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[8].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[8].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[8].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[8].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[8].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #f90033; color: white"
            >
              {{
                imgSrc[8].name ? imgSrc[8].name.replace(/\..+$/, '') : 'Hero 4'
              }}
            </div>
            <back-state-com
              :state="imgSrc[8].state"
              :source="imgSrc[8].source"
              :key="imgSrc[8].time"
              v-if="
                imgSrc[8].state.style.state &&
                imgSrc[8].state.style.swapState == false &&
                pickR1 == false
              "
            />
          </div>
          <div
            :style="{
              width: pickR1 ? 50 + '%' : '20' + '%',
              'z-index': pickR1 ? 99 : 0,
              left: pickR1 ? '50' + '%' : '80' + '%',
            }"
            class="card absolute transition-all duration-[0.6s] overflow-hidden"
          >
            <div
              v-if="lockR5"
              class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center"
            >
              <img src="../assets/locked.png" class="w-12 h-12" />
            </div>
            <!-- <img v-if="!imgSrc[9].state.source.includes('webm')" style="object-position: 100% 20%;"
              class="relative w-full object-cover h-[250px]" :src="link + imgSrc[9].source"> -->
            <div v-if="!imgSrc[9].state != ''">
              <div v-if="!imgSrc[9].state.source.includes('webm')">
                <transition
                  v-if="pickR1"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[9].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[9].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <img
                      class="relative w-full object-cover h-[250px] inner"
                      :src="link + imgSrc[9].source"
                      style="object-position: 100% 20%"
                    />
                  </div>
                </transition>
                <img
                  v-else
                  class="relative w-full object-cover h-[250px] inner"
                  :src="link + imgSrc[9].source"
                  style="object-position: 100% 20%"
                />
              </div>
              <div v-else>
                <transition
                  v-if="pickR1"
                  name="fade1"
                  :duration="{ enter: 2000, leave: 500 }"
                >
                  <div
                    :key="imgSrc[9].time"
                    :style="{
                      '--intro':
                        'url(' + link + imgSrc[9].state.style.introSource + ')',
                    }"
                    class="backImage"
                  >
                    <video
                      style="object-position: 100% 20%"
                      autoplay="True"
                      muted="True"
                      loop="True"
                      id="video"
                      class="relative w-full object-cover h-[250px] inner"
                    >
                      <source :src="link + imgSrc[9].source" type="video/mp4" />
                    </video>
                  </div>
                </transition>
                <video
                  v-else
                  :key="imgSrc[9].time"
                  style="object-position: 100% 20%"
                  autoplay="True"
                  muted="True"
                  loop="True"
                  id="video"
                  class="relative w-full object-cover h-[250px] inner"
                >
                  <source :src="link + imgSrc[9].source" type="video/mp4" />
                </video>
              </div>
            </div>
            <div v-else>
              <img
                class="relative w-full object-cover h-[250px] inner"
                :src="link + imgSrc[9].state.style.introSource"
                style="object-position: 100% 20%"
              />
            </div>
            <div
              class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2"
              style="background-color: #f90033; color: white"
            >
              {{
                imgSrc[9].name ? imgSrc[9].name.replace(/\..+$/, '') : 'Hero 5'
              }}
            </div>
            <back-state-com
              :state="imgSrc[9].state"
              :source="imgSrc[9].source"
              :key="imgSrc[9].time"
              v-if="
                imgSrc[9].state.style.state &&
                imgSrc[9].state.style.swapState == false &&
                pickR1 == false
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Dialog from 'primevue/dialog';
import VueDragResize from 'vue3-drag-resize';
import db from '../firebase';
import { ref, onValue, get, child } from 'firebase/database';
import CountDown from '../components/CountDown.vue';
import Clock from '../components/Clock.vue';
import Graph from '../components/Graph.vue';
import ImageCom from '../components/image.vue';
import VideoCom from '../components/video.vue';
import BackStateCom from '../components/BackState.vue';

export default {
  name: 'DashboardView',
  components: {
    VueDragResize,
    Dialog,
    CountDown,
    Clock,
    Graph,
    ImageCom,
    VideoCom,
    BackStateCom,
  },

  data() {
    return {
      showTemplate: true,
      startDialog: false,
      link: process.env.VUE_APP_Link,
      inputs: [],
      key: 0,
      showElement: false,
      delay: 1,
      id: this.$route.query.data,
      color: 'oldlace',
      num: 0,
      boughtLoad: false,
      audio: '',
      time: 0,
      newBanPick: false,
      firstLoad: true,
      imgSrc: [
        {
          time: '',
          source: '',
          name: '',
          state: '',
        },
        {
          time: '',
          source: '',
          name: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
        {
          time: '',
          name: '',
          source: '',
          state: '',
        },
      ],
      lastAnimation: 0,
      pickL1: false,
      pickL2: false,
      pickL3: false,
      pickR1: false,
      pickR2: false,
      pickR3: false,
      pickR4: false,

      lockL1: false,
      lockL2: false,
      lockL3: false,
      lockL4: false,
      lockL5: false,
      lockR1: false,
      lockR2: false,
      lockR3: false,
      lockR4: false,
      lockR5: false,

      heroL1: 'Hero 1',
      heroL2: 'Hero 2',
      heroL3: 'Hero 3',
      heroL4: 'Hero 4',
      heroL5: 'Hero 5',
      heroR1: 'Hero 1',
      heroR2: 'Hero 2',
      heroR3: 'Hero 3',
      heroR4: 'Hero 4',
      heroR5: 'Hero 5',
    };
  },
  watch: {
    pickL1() {
      if (this.pickL1) {
        this.imgSrc[0].source = this.imgSrc[0].state.style.introSource;
      } else {
        this.imgSrc[0].source = this.imgSrc[0].state.source;
      }
    },
    pickL2() {
      if (this.pickL2) {
        this.imgSrc[1].source = this.imgSrc[1].state.style.introSource;
        this.imgSrc[2].source = this.imgSrc[2].state.style.introSource;
      } else {
        this.imgSrc[1].source = this.imgSrc[1].state.source;
        this.imgSrc[2].source = this.imgSrc[2].state.source;
      }
    },
    pickL3() {
      if (this.pickL3) {
        this.imgSrc[3].source = this.imgSrc[3].state.style.introSource;
        this.imgSrc[4].source = this.imgSrc[4].state.style.introSource;
      } else {
        this.imgSrc[3].source = this.imgSrc[3].state.source;
        this.imgSrc[4].source = this.imgSrc[4].state.source;
      }
    },
    pickR1() {
      if (this.pickR1) {
        this.imgSrc[8].source = this.imgSrc[8].state.style.introSource;
        this.imgSrc[9].source = this.imgSrc[9].state.style.introSource;
      } else {
        this.imgSrc[8].source = this.imgSrc[8].state.source;
        this.imgSrc[9].source = this.imgSrc[9].state.source;
      }
    },
    pickR2() {
      if (this.pickR2) {
        this.imgSrc[7].source = this.imgSrc[7].state.style.introSource;
      } else {
        this.imgSrc[7].source = this.imgSrc[7].state.source;
      }
    },

    pickR3() {
      if (this.pickR3) {
        this.imgSrc[6].source = this.imgSrc[6].state.style.introSource;
      } else {
        this.imgSrc[6].source = this.imgSrc[6].state.source;
      }
    },
    pickR4() {
      if (this.pickR4) {
        this.imgSrc[5].source = this.imgSrc[5].state.style.introSource;
      } else {
        this.imgSrc[5].source = this.imgSrc[5].state.source;
      }
    },
  },

  created() {
    var result = this.id.includes('mmm');
    let element_id = '';
    if (result) {
      var split = this.id.split('mmm');
      element_id = split[0];

      onValue(ref(db, element_id), (snapshot) => {
        const data = snapshot.val();
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          if (data.overlays != '') {
            this.inputs = data.overlays;
            this.time = data.time;
            if (data.pickSlot != '') {
              this[data.pickSlot] = !this[data.pickSlot];
              var pickList = [
                'pickL1',
                'pickL2',
                'pickL3',
                'pickR1',
                'pickR2',
                'pickR3',
                'pickR4',
              ];

              // for (var i = 0; i < pickList.length; i++) {
              //   if (pickList[i] != data.pickSlot) {
              //     this[pickList[i]] = false;
              //   }
              // }
            }
            // else
            // {
            //   this['pickL1'] = false;
            //   this['pickL2'] = false;
            //   this['pickL3'] = false;
            //   this['pickR1'] = false;
            //   this['pickR2'] = false;
            //   this['pickR3'] = false;
            // }

            // if (data.audio != "" && data.audio != null && data.audio != []) {
            //   var split = this.link.split('.com/')[0];
            //   for (var i = 0; i < data.audio.length; i++) {
            //     this.audio = split + '.com/Audio/' + data.audio[i];
            //     this.time = data.time;
            //     if (data.audioType[i] != 'pick') {
            //       if (data.audioType[i] == 'ban') {
            //         var audios = new Audio(split + '.com/Audio/SELECT-HERO-FOR-BAN.wav');
            //         var play = audios.play();
            //         if (play !== undefined) {
            //           play.then(_ => {
            //           }).catch(error => {
            //             console.log(error);
            //             console.log('error audio');
            //           });
            //         }
            //         try {
            //           setTimeout(() => {
            //             var audio = new Audio(this.audio);
            //             var play = audio.play();
            //             if (play !== undefined) {
            //               play.then(_ => {
            //                 // Autoplay started!
            //               }).catch(error => {
            //                 console.log(error);
            //                 console.log('error audio');
            //               });
            //             }
            //           }, 500);
            //         } catch (error) {
            //           console.log('error audio');
            //         }
            //       } else {
            //         var audio = new Audio(this.audio);
            //         var play = audio.play();
            //         if (play !== undefined) {
            //           play.then(_ => {
            //           }).catch(error => {
            //             console.log(error);
            //             console.log('error audio');
            //           });
            //         }
            //       }
            //     } else {
            //       var audios = new Audio(split + '.com/Audio/SELECT-HERO-FOR-PICK.wav');
            //       var play = audios.play();
            //       if (play !== undefined) {
            //         play.then(_ => {
            //         }).catch(error => {
            //           console.log(error);
            //           console.log('error audio');
            //         });
            //       }

            //       try {
            //         setTimeout(() => {
            //           var audio = new Audio(this.audio);
            //           var play = audio.play();
            //           if (play !== undefined) {
            //             play.then(_ => {
            //               // Autoplay started!
            //             }).catch(error => {
            //               console.log(error);
            //               console.log('error audio');
            //             });
            //           }
            //         }, 2000);
            //       } catch (error) {
            //         console.log(error);
            //         console.log('error audio');
            //       }

            //     }
            //   }
            // }
            // Function to play audio with error handling
            function playAudio(audioSource) {
              const audio = new Audio(audioSource);
              const play = audio.play();
              if (play !== undefined) {
                play
                  .then(() => {
                    // Audio started playing
                  })
                  .catch((error) => {
                    console.log('Error playing audio:', error);
                  });
              }
            }

            if (
              data.audio &&
              Array.isArray(data.audio) &&
              data.audio.length > 0
            ) {
              var split = this.link.split('.com/')[0];
              for (var i = 0; i < data.audio.length; i++) {
                this.audio = split + '.com/Audio/' + data.audio[i];
                this.time = data.time;

                if (data.audioType[i] != 'pick') {
                  if (data.audioType[i] == 'ban') {
                    playAudio(split + '.com/Audio/SELECT-HERO-FOR-BAN.wav');

                    try {
                      setTimeout(() => {
                        playAudio(this.audio);
                      }, 500);
                    } catch (error) {
                      console.log('Error during ban audio delay:', error);
                    }
                  } else {
                    playAudio(this.audio);
                  }
                } else {
                  playAudio(split + '.com/Audio/SELECT-HERO-FOR-PICK.wav');

                  try {
                    setTimeout(() => {
                      playAudio(this.audio);
                    }, 2000);
                  } catch (error) {
                    console.log('Error during pick audio delay:', error);
                  }
                }
              }
            }

            for (var i = 0; i < this.inputsAll.length; i++) {
              if (this.inputsAll[i].type == 'image') {
                if (this.delay < this.inputsAll[i].style.delay) {
                  this.delay = this.inputsAll[i].style.delay;
                }
              }
              if (this.inputsAll[i].link > 0 && this.inputsAll[i].link <= 10) {
                for (let i = 0; i < this.inputsAll.length; i++) {
                  const link = this.inputsAll[i].link;
                  if (link >= 1 && link <= 10) {
                    if (
                      this.imgSrc[link - 1].time != this.inputsAll[i].style.time
                    ) {
                      this.imgSrc[link - 1].source = this.inputsAll[i].source;
                      this.imgSrc[link - 1].state = this.inputsAll[i];
                      this.lastAnimation = link;
                      this.imgSrc[link - 1].time = this.inputsAll[i].style.time;
                      if (this.inputsAll[i].style.swapState == false) {
                        // if (this.lastAnimation === 1) {
                        //   this.expand('pickL1');
                        // } else if ( this.lastAnimation === 8) {
                        //   this.expand('pickR2');
                        // } else if (this.lastAnimation === 7) {
                        //   this.expand('pickR3');
                        // } else if (this.lastAnimation === 6) {
                        //   this.expand('pickR4');
                        // }
                      }
                    }
                  }
                  if (link >= 91 && link <= 100) {
                    if (
                      this.inputsAll[i].source != this.imgSrc[link - 91].source
                    ) {
                      this.imgSrc[link - 91].name = this.inputsAll[i].source;
                    }
                  }
                }
              }
            }
            this.delayedShow(this.delay);
          } else {
            this.inputs = '';
            this.showElement = false;
          }
        }
      });
    } else {
      onValue(ref(db, 'overlay/'), (snapshot) => {
        const data = snapshot.val();
        if (data.overlays != '') {
          this.inputs = data.overlays;
          for (var i = 0; i < this.inputsAll.length; i++) {
            if (this.inputsAll[i].type == 'image') {
              if (this.delay < this.inputsAll[i].style.delay) {
                this.delay = this.inputsAll[i].style.delay;
              }
            }
          }
          this.delayedShow(this.delay);
        } else {
          this.inputs = '';
          this.showElement = false;
        }
      });
    }
  },

  methods: {
    expand(val) {
      if (
        [
          'pickL1',
          'pickL2',
          'pickL3',
          'pickR1',
          'pickR2',
          'pickR3',
          'pickR4',
        ].includes(val)
      ) {
        this[val] = true;
        if (['pickL1', 'pickR2', 'pickR3', 'pickR4'].includes(val)) {
          setTimeout(() => {
            this[val] = false;
          }, 4500);
        }
      }
    },
    delayedShow(idx) {
      let delay = 1500 * idx,
        self = this;

      setTimeout(() => {
        self.showElement = true;
      }, delay);
    },

    preloadImage() {
      var type = 'image';
      var token = this.id;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: type,
      };
      axios
        .post('get_all_files', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            var image = resp.data.data,
              file = [];
            for (var i = 0; i < image.length; i++) {
              file[i] = new Image();
              file[i].src = this.link + image[i].file_name;
            }
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },

    getOverlay() {
      var result = this.id.includes('mmm');
      let token = '';
      let element_id = '';
      if (result) {
        var split = this.id.split('mmm');
        token = split[1];
        element_id = split[0];

        var data = {
          id: element_id,
        };
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        axios
          .post('get_overlay_by_id', data, config)
          .then((resp) => {
            if (resp.data.data != null) {
              if (resp.data.data.status == 1) {
                this.inputs = JSON.parse(resp.data.data.component);
                if (resp.data.data.title == 'Game Play') {
                  this.color = 'green';
                  this.newBanPick = false;
                } else if (resp.data.data.title == 'Ban Pick New') {
                  this.color = 'green';
                  this.newBanPick = true;
                } else if (resp.data.data.title == 'Item Build') {
                  this.color = 'oldlace';
                  this.newBanPick = false;
                } else if (resp.data.data.title == 'Ban LED') {
                  this.color = 'black';
                  this.newBanPick = false;
                } else {
                  this.color = 'green';
                  this.newBanPick = false;
                }
                for (var i = 0; i < this.inputsAll.length; i++) {
                  if (this.inputsAll[i].type == 'image') {
                    if (this.delay < this.inputsAll[i].style.delay) {
                      this.delay = this.inputsAll[i].style.delay;
                    }
                  }
                  if (
                    this.inputsAll[i].link > 0 &&
                    this.inputsAll[i].link <= 10
                  ) {
                    for (let i = 0; i < this.inputsAll.length; i++) {
                      const link = this.inputsAll[i].link;
                      if (link >= 1 && link <= 10) {
                        this.imgSrc[link - 1].source = this.inputsAll[i].source;
                        this.imgSrc[link - 1].state = this.inputsAll[i];
                        if (
                          this.imgSrc[link - 1].time !=
                          this.inputsAll[i].style.time
                        ) {
                          this.lastAnimation = link;
                        }
                        this.imgSrc[link - 1].time =
                          this.inputsAll[i].style.time;
                      }
                      if (link >= 91 && link <= 100) {
                        if (
                          this.inputsAll[i].source !=
                          this.imgSrc[link - 91].source
                        ) {
                          this.imgSrc[link - 91].name =
                            this.inputsAll[i].source;
                        }
                      }
                      //console.log(this.imgSrc);
                    }
                  }
                }
                this.delayedShow(this.delay);
              }
            } else {
              this.inputs = '';
            }
          })
          .catch((err) => {
            if (err.response.statusText == 'Unauthorized') {
              localStorage.removeItem('token');
              localStorage.removeItem('username');
              localStorage.removeItem('id');
              this.$router.push('login');
            } else {
              console.log(err.response.statusText);
            }
          });
      } else {
        token = this.id;
        element_id = '';
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        axios
          .get('get_overlay_by_active', config)
          .then((resp) => {
            if (resp.data.data != null) {
              this.inputs = JSON.parse(resp.data.data.component);
              for (var i = 0; i < this.inputsAll.length; i++) {
                if (this.inputsAll[i].type == 'image') {
                  if (this.delay < this.inputsAll[i].style.delay) {
                    this.delay = this.inputsAll[i].style.delay;
                  }
                }
              }
              this.delayedShow(this.delay);
            } else {
              this.inputs = '';
            }
          })
          .catch((err) => {
            if (err.response.statusText == 'Unauthorized') {
              localStorage.removeItem('token');
              localStorage.removeItem('username');
              localStorage.removeItem('id');
              this.$router.push('login');
            } else {
              console.log(err.response.statusText);
            }
          });
      }
    },

    Live() {
      this.startDialog = false;
    },

    decrypt(src) {
      if (src != null) {
        const passphrase = 'Overlay';
        const bytes = this.$CryptoJS.AES.decrypt(src, passphrase);
        const originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
        return originalText;
      } else {
        return src;
      }
    },
  },

  mounted() {
    this.showTemplate = true;
    this.getOverlay();
  },

  computed: {
    inputsAll() {
      if (this.inputs != '') {
        let inputNGroup = this.inputs.filter((input) => input.type !== 'group');
        let array = this.inputs.filter((input) => input.type === 'group');
        let inputWGroup = [];
        array.forEach((element) => {
          element.element.forEach((el) => {
            inputWGroup.push(el);
          });
        });
        return inputNGroup.concat(inputWGroup);
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active .inner,
.fade-leave-active .inner {
  transition: all 1s ease;
}

.fade-enter-active .inner {
  transition: 1.5s all 1s ease;
}

.fade-enter-to .inner {
  padding: 0;
}

.fade-leave-from .inner,
.fade-leave-to .inner {
  display: none;
}

.fade-enter-from .inner {
  padding-inline: 370px;
}

.fade1-enter-active .inner,
.fade1-leave-active .inner {
  transition: all 1s ease;
}

.fade1-enter-active .inner {
  transition: 1.5s all 1s ease;
}

.fade1-enter-to .inner {
  padding: 0;
}

.fade1-leave-from .inner,
.fade1-leave-to .inner {
  display: none;
}

.fade1-enter-from .inner {
  padding-inline: 180px;
}

// .nested-enter-active {
//     animation: slide-down 0.5s;
// }

// .nested-leave-active {
//     animation: slide-up 1s;
// }

.backImage {
  // background: #AB3E5B;
  background-image: var(--intro);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 20%;
}

.nested-enter-from .inner {
  height: 0px;
}

.nested-enter-to .inner {
  height: 300px;
}

.nested-enter-active .inner {
  transition: all 1.5s ease-in-out;
}

.nested-leave-from .inner {
  padding-inline: 400px;
}

.nested-leave-to .inner {
  padding: 0;
}

.nested-leave-active .inner {
  transition: all 1.5s ease-in-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-300px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-300px);
  }
}

.fluidMedia {
  /* background-color: oldlace; */
  overflow: hidden;
  width: 1920px;
  height: 1080px;
}

.rotate0 {
  -ms-transform: rotate(0deg);
  /* IE 9 */
  transform: rotate(0deg);
}

.rotate90 {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}

.rotate180 {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  transform: rotate(180deg);
}

.rotate270 {
  -ms-transform: rotate(270deg);
  /* IE 9 */
  transform: rotate(270deg);
}

.overrides {
  --transition__duration: 1s;
  /* default: 2.5s */
  --transition__easing: linear;
  /* default: cubic-bezier(.25, 1, .30, 1) */
  --transition__delay: var(--delay);
  /* default: 0 */
}

.grayClass {
  filter: grayscale(100%);
}

@keyframes blinkingBackground {
  0% {
    opacity: 100%;
  }

  25% {
    opacity: 10%;
  }

  50% {
    opacity: 100%;
  }

  75% {
    opacity: 10%;
  }

  100% {
    opacity: 100%;
  }
}

.blinking {
  animation: blinkingBackground 2s infinite;
}

.right {
  animation: right-animate 1s ease alternate;
}

@keyframes right-animate {
  0% {
    transform: width(0px);
  }

  100% {
    transform: width(100px);
  }
}
</style>
