import { createRouter,createWebHistory, createWebHashHistory ,createMemoryHistory} from 'vue-router';
import BodyCom from '../components/body.vue';
import DashboardView from '../views/DashboardView.vue';
import OverlayView from '../views/OverlayView.vue';
import OverlayEditorView from '../views/OverlayEditor.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import LiveView from '../views/LiveView.vue';
import ChangePasswordView from '../views/ChangePasswordView.vue';
import GraphView from '../views/GraphView.vue';
import BanPickView from '../views/BanPickView.vue';
import AdminView from '@/views/AdminView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: BodyCom,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'home',
        component: OverlayView,
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
      {
        path: 'dashboard/:id',
        name: 'list',
        component: OverlayView,
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
      // {
      //   path: 'overlay',
      //   name: 'overlay',
      //   component: OverlayView,
      //   meta: {
      //     requiresAuth: true,
      //     title: 'OverLay',
      //   },
      // },
       {
        path: 'admin',
        name: 'admin',
        component: AdminView,
        meta: {
          requiresAuth: true,
          title: 'Admin',
        },
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: ChangePasswordView,
        meta: {
          requiresAuth: true,
          title: 'Change Password',
        },
      },
    ],
  },
  {
    path: '/live',
    name: 'live',
    component: LiveView,
    meta: {
      // guest: true,
      // requiresAuth: false,
      title: 'Live Stream',
    },
  },
  {
    path: '/graph',
    name: 'graph',
    component: GraphView,
    meta: {
      // guest: true,
      // requiresAuth: false,
      title: 'Live Stream',
    },
  },
  {
    path: '/banpick',
    name: 'banpick',
    component: BanPickView,
    meta: {
      // guest: true,
      // requiresAuth: false,
      title: 'Live Stream',
    },
  },
  {
    path: '/overlay-editor',
    name: 'overlay-editor',
    component: OverlayEditorView,
    meta: {
      requiresAuth: true,
      title: 'Overlay Editor',
    },
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      guest: true,
      title: 'Login',
    },
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: RegisterView,
  //   meta: {
  //     guest: true,
  //     title: 'Login',
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
function loggedIn() {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  } else {
    return true;
  }
}
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (loggedIn()) {
      next({
        path: '/dashboard',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }
  else if (to.matched.some((record) => record.meta.guest)) {
      next({
        path: '/live*',
        query: { redirect: to.fullPath },
      });
  }
  else if (to.matched.some((record) => record.meta.guest)) {
      next({
        path: '/graph*',
        query: { redirect: to.fullPath },
      });
  }
  else if (to.matched.some((record) => record.meta.guest)) {
    next({
      path: '/banpick*',
      query: { redirect: to.fullPath },
    });
}
    
  else
  {
    next();
  }
});


export default router;
