<template>
  <div v-if="showTemplate">
    <header-con :title-a="'Application'" :title-b="'Admin'"></header-con>
    <div class="mt-5">
      <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
        <div
          v-for="(file, name) in allFile"
          :key="file.id"
          class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2"
        >
          <div
            class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in image"
          >
            <div class="absolute left-0 top-0 mt-3 ml-3">
              <!-- <input class="form-check-input border border-slate-500" type="checkbox" 
                  disabled /> -->
              <!-- @click="ShowOverlay(overlay.id, overlay.status)"  -->
            </div>
            <a
              href="javascript:;"
              class="w-3/5 file__icon file__icon--image mx-auto"
              @click="ShowDialogImage(name)"
            >
              <div class="file__icon--image__preview image-fit">
                <img
                  alt="Midone - HTML Admin Template"
                  src="../assets/dist/images/layer.png"
                />
              </div>
            </a>
            <a
              href="javascript:;"
              class="block font-medium mt-4 text-center truncate name"
              >{{ name.toString().toUpperCase() }}</a
            >
            <div class="text-slate-500 text-xs text-center mt-0.5">
              {{ file.length }}
            </div>
            <div class="absolute top-0 right-0 mr-2 mt-3 dropdown ml-auto">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:;"
                aria-expanded="false"
                data-tw-toggle="dropdown"
                id="defaultDropdown"
              >
                <i
                  data-lucide="more-vertical"
                  class="w-5 h-5 text-slate-500 bi bi-three-dots"
                ></i>
              </a>
              <div class="dropdown-menu w-40">
                <ul class="dropdown-content">
                  <li>
                    <a
                      href=""
                      class="dropdown-item"
                      @click.prevent="ShowDialogAddImage(name)"
                    >
                      <i class="w-4 h-4 mr-2 bi bi-clipboard2"></i>
                      Add File
                    </a>
                  </li>
                  <!-- <li>
                    <a href="" class="dropdown-item">
                      <i class="w-4 h-4 mr-2 bi bi-pencil-square"></i>
                      Properties
                    </a>
                  </li>
                  <li>
                    <a href="" class="dropdown-item">
                      <i class="w-4 h-4 mr-2 bi bi-clipboard2"></i>
                      Duplicate
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="dialogAdd"
    :style="{ width: '400px' }"
    :modal="true"
    :draggable="false"
    class="p-fluid"
    :closeOnEscape="false"
    :closable="true"
  >
  <template #header>
      <div style="font-weight: bold">ADD HERO {{ state.toUpperCase() }}</div>
    </template>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12">
        <!-- BEGIN: Personal Information -->
        <div class="intro-y box">
          <div class="">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12">
                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Title</label
                  >
                  <input
                    id="update-profile-form-6"
                    type="text"
                    class="form-control"
                    placeholder="Title"
                    v-model="title"
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-4">
              <button
                type="button"
                class="btn btn-primary w-20 mr-1"
                @click="onUploadImage"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <!-- END: Personal Information -->
      </div>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="dialogImage"
    :style="{ width: '1700px' }"
    :modal="true"
    :draggable="false"
    class="p-fluid"
    :closeOnEscape="false"
  >
    <template #header>
      <div style="font-weight: bold">{{ fileMissing }}</div>
    </template>
    <div
      class="intro-y grid grid-cols-12 gap-3 sm:gap-6 p-5"
      v-if="tabActive != 'upload'"
    >
      <div
        class="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2"
        v-for="file in files"
        :key="file.id"
      >
        <div
          class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in image"
        >
          <a
            href="javascript:;"
            class="w-3/5 file__icon file__icon--image mx-auto"
          >
            <div class="file__icon--image__preview image-fit">
              <img
                v-if="file.type == 'image'"
                alt="Midone - HTML Admin Template"
                :src="link + file.file_name"
              />
              <video muted v-else-if="file.type == 'video'">
                <source :src="link + file.file_name" />
              </video>
            </div>
          </a>
          <a
            href="javascript:;"
            class="block font-medium mt-4 text-center truncate name"
            >{{ file.title }}</a
          >
          <div class="text-slate-500 text-xs text-center mt-0.5"></div>
          <div class="absolute top-0 right-0 mr-2 mt-3 ml-auto">
            <a
              href="javascript:;"
              @click="DeleteFile(file.id)"
              v-if="file.state == null"
            >
              <i class="w-4 h-4 bi bi-trash3-fill"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
  <Toast position="top-right" group="tr" />
</template>
<script>
import axios from 'axios';
import HeaderCon from '../components/header.vue';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';

export default {
  name: 'AdminView',
  components: {
    HeaderCon,
    Card,
    Dialog,
    Toast,
  },
  data() {
    return {
      showTemplate: false,
      allFile: [],
      dialogAdd: false,
      dialogCopy: false,
      dialogImage: false,
      title: '',
      file_type: '',
      state: '',
      files: [],
      link: process.env.VUE_APP_Link,
      fileMissing: 'Nothing',
      hero: [
        'Aamon',
        'Akai',
        'Aldous',
        'Alice',
        'Alpha',
        'Alucard',
        'Angela',
        'Argus',
        'Arlott',
        'Atlas',
        'Aulus',
        'Aurora',
        'Badang',
        'Balmond',
        'Bane',
        'Barats',
        'Baxia',
        'Beatrix',
        'Belerick',
        'Benedetta',
        'Brody',
        'Bruno',
        'Camilla',
        'Cecilion',
        'Chang-e',
        'Chou',
        'Claude',
        'Clint',
        'Cyclops',
        'Diggie',
        'Dyrroth',
        'Edith',
        'Esmeralda',
        'Estes',
        'Eudora',
        'Fanny',
        'Faramis',
        'Floryn',
        'Franco',
        'Fredrinn',
        'Freya',
        'Gatotkaca',
        'Gloo',
        'Gord',
        'Granger',
        'Grock',
        'Guinevere',
        'Gusion',
        'Hanabi',
        'Hanzo',
        'Harith',
        'Harley',
        'Hayabusa',
        'Helcurt',
        'Hilda',
        'Hylos',
        'Irithel',
        'Ixia',
        'Jawhead',
        'Johnson',
        'Joy',
        'Julian',
        'Kadita',
        'Kagura',
        'Kaja',
        'Karina',
        'Karrie',
        'Khaleed',
        'Khufra',
        'Kimmy',
        'Lancelot',
        'Lapu-Lapu',
        'Layla',
        'Leomord',
        'Lesley',
        'Ling',
        'Lolita',
        'Lunox',
        'Luo-Yi',
        'Lylia',
        'Martis',
        'Masha',
        'Mathilda',
        'Melissa',
        'Minotaur',
        'Minsitthar',
        'Miya',
        'Moskov',
        'Nana',
        'Natalia',
        'Natan',
        'Novaria',
        'Nolan',
        'Odette',
        'Paquito',
        'Pharsa',
        'Phoveus',
        'Popol-and-Kupa',
        'Rafaela',
        'Roger',
        'Ruby',
        'Saber',
        'Selena',
        'Silvanna',
        'Sun',
        'Terizla',
        'Thamuz',
        'Tigreal',
        'Uranus',
        'Vale',
        'Valentina',
        'Valir',
        'Vexana',
        'WanWan',
        'X-Borg',
        'Xavier',
        'Yi-Sun-Shin',
        'Yin',
        'Yu-Zhong',
        'Yve',
        'Zhask',
        'Zilong',
        'Cici',
        'Chip',
        'Zhu-Xin',
        'Suyou',
        'Lukas',
        'Kalea',
      ],
    };
  },
  mounted() {
    this.GetImage();
    this.showTemplate = true;
  },
  methods: {
    decrypt(src) {
      if (src != null) {
        const passphrase = 'Overlay';
        const bytes = this.$CryptoJS.AES.decrypt(src, passphrase);
        const originalText = bytes.toString(this.$CryptoJS.enc.Utf8);
        return originalText;
      } else {
        return src;
      }
    },
    async GetImage() {
      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        type: 'image',
        id: this.decrypt(this.id),
      };
      await axios
        .post('get_all_images', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            this.allFile = resp.data.data;
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },
    ShowDialogAddImage(name) {
      this.HideDropdown();
      this.title = '';
      this.dialogAdd = true;
      this.files = this.allFile[name];
      this.state = name;

      if (name == 'video') {
        this.state = 'hero';
      }
      if (name == 'videoTall') {
        this.state = 'tall';
      }
      this.file_type = this.files[0]['type'];
    },

    ShowDialogImage(name) {
      this.dialogImage = true;
      this.files = this.allFile[name];
      if (name != 'item' && name != 'other') {
        this.fileMissing = this.findMissingObjects(
          this.hero,
          this.files,
          'title'
        );
      } else if (name == 'item' || name == 'other') {
        this.fileMissing = [];
      }
    },

    findMissingObjects(mainArray, compareArray, key) {
      return mainArray.filter(
        (item) => !compareArray.some((obj) => obj[key] === item)
      );
    },
    async onUploadImage() {
      var token = this.$store.state.token;
      var config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      var data = {
        title: this.title,
        file_type: this.file_type,
        state: this.state,
      };
      await axios
        .post('add_hero_name', data, config)
        .then((resp) => {
          if (resp.data.status == 'success') {
            this.GetImage();
            this.dialogAdd = false;
            this.Toast('success', 'Saved', '');
          } else {
            this.Toast('error', 'Fail!', resp.data.message);
          }
        })
        .catch((err) => {
          if (err.response.statusText == 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            this.$router.push('login');
          } else {
            console.log(err.response.statusText);
          }
        });
    },
    Toast(state, sum, det) {
      this.$toast.add({
        severity: state,
        summary: sum,
        detail: det,
        group: 'tr',
        life: 1000,
      });
    },
    HideDropdown() {
      const el = document.querySelector('#defaultDropdown');
      const dropdown = tailwind.Dropdown.getOrCreateInstance(el);
      dropdown.hide();
    },
  },
};
</script>

<style scoped>
#video-viewport {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: contain;
  /* overflow: hidden; */
}
</style>
